import { Theme } from 'theme'

const theme: Theme = {
  background: '#d2ecff',
  primary: '#0072BC',
  accent: '#0072BC',
  error: '#d35d6e',
  disabled: '#93b8cb',
  lightGray: '#ddd',
  font: {
    family: 'Museo Sans, Arial, Helvetica, sans-serif',
    size: '16px',
    largeSize: '18px',
    weight: 'normal',
    color: '#08354C',
    lineHeight: 1.4
  },
  heading: {
    family: 'NeoTech-Medium, Arial, Helvetica, sans-serif',
    weight: 'normal',
    size: '30px',
    largeSize: '42px'
  },
  radius: 3,
  breakpoints: [576, 768, 992, 1200]
}

export default theme
